<template>
  <div>
    <b-container>
      <b-row>
        <b-col md="12">
          <div class="mt-2" v-if="deal_name.length > 240">
            <b-alert show
              >Deal has ({{ deal_name.length }}) reached 240 characters
            </b-alert>
          </div>

          <div class="buttons-container">
            <b-button
              tabindex="0"
              v-b-tooltip.click
              title="Copied to clipboard!"
              v-clipboard="() => hyphenedDealNames"
              class="btn btn-danger my-5"
            >
              <BIconClipboard />
            </b-button>
            <router-link to="/" class="btn btn-danger ml-2 my-5">
              <BIconPencilSquare />
            </router-link>
            <b-button @click="restartForm()" class="btn btn-danger ml-2"
              ><BIconArrowClockwise
            /></b-button>
          </div>

          <div class="deal-container">
            <p>{{ hyphenedDealNames }}</p>
            <p v-if="!deal_name">
              Oops, please fill out the form first!
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss" scoped>
.deal-container {
  p {
    font-family: "Courier New", monospace;
  }
}
</style>

<script>
import { mapState } from "vuex";
import {
  BIconClipboard,
  BIconArrowClockwise,
  BIconPencilSquare,
} from "bootstrap-vue";
export default {
  name: "Result",
  components: {
    BIconClipboard,
    BIconArrowClockwise,
    BIconPencilSquare,
  },
  computed: {
    ...mapState(["deal_name"]),
    hyphenedDealNames() {
      return this.deal_name.replace(/,/g, "-");
    },
  },
  methods: {
    restartForm() {
      window.location.href = "/";
    },
  },
};
</script>
